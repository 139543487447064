import React, {
  createContext,
  useCallback,
  useContext,
  useReducer
} from 'react'
import PropType from 'prop-types'

import Dialog from '../components/Dialog'

export const DialogContext = createContext()

const initialState = {
  open: false,
  title: '',
  message: '',
  agreeLabel: '',
  cancelLabel: 'Cancelar',
  agreementAction: null,
  loading: false
}

function reducer(state, action) {
  switch (action.type) {
    case 'open':
      return {
        ...state,
        open: true,
        title: action.title,
        message: action.message,
        agreeLabel: action.agreeLabel,
        agreementAction: action.callback
      }
    case 'close':
      return {
        ...state,
        open: false,
        loading: false
      }
    case 'loading':
      return {
        ...state,
        loading: true
      }
    default:
      throw new Error()
  }
}

export const DialogProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const handleOpenDialog = useCallback(
    ({ title, message, agreeLabel }, callback) =>
      dispatch({
        type: 'open',
        title,
        message,
        agreeLabel,
        callback: async () => {
          dispatch({ type: 'loading' })
          await callback()
          dispatch({ type: 'close' })
        }
      }),
    []
  )

  const handleClose = useCallback(() => dispatch({ type: 'close' }), [])

  return (
    <DialogContext.Provider value={{ handleOpenDialog }}>
      <Dialog
        open={state.open}
        title={state.title}
        message={state.message}
        handleClose={handleClose}
        agreeLabel={state.agreeLabel}
        agreementAction={state.agreementAction}
        loading={state.loading}
      />
      {children}
    </DialogContext.Provider>
  )
}

DialogProvider.propTypes = {
  children: PropType.node.isRequired
}

export function useDialog() {
  const context = useContext(DialogContext)

  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider')
  }

  return context
}
