import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 8,
    boxShadow: 'none'
  },
  title: {
    '& h2': {
      fontWeight: 'bold'
    }
  },
  actions: {
    padding: '0 24px 24px 24px'
  }
}))

export default useStyles
