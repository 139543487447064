import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'

import useStyles from './styles'

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade direction="down" ref={ref} {...props} />
})

const CustomDialog = ({
  title,
  message,
  open,
  handleClose,
  agreeLabel,
  agreementAction,
  loading
}) => {
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      disableBackdropClick={loading}
      disableEscapeKeyDown={loading}
      classes={{
        paper: classes.paper
      }}
    >
      <DialogTitle
        classes={{ root: classes.title }}
        id="alert-dialog-slide-title"
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} color="secondary" disabled={loading}>
          Cancelar
        </Button>
        <Button
          onClick={agreementAction}
          variant="contained"
          disableElevation
          color="primary"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress color="primary" size={24} />
          ) : (
            `${agreeLabel}`
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CustomDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  agreeLabel: PropTypes.string.isRequired,
  agreementAction: PropTypes.func,
  loading: PropTypes.bool.isRequired
}

export default CustomDialog
